import React, { useEffect } from "react";

const GoogleAd = ({ adSlot, style }) => {
  useEffect(() => {
    try {
      if (typeof window !== "undefined" && window.adsbygoogle) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (e) {
      console.error("AdSense Error:", e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: "block",
        textAlign: "center",
        margin: "20px auto", // Center the ad
        maxWidth: "100%", // Ensure responsive width
        ...style,
      }}
      data-ad-client="ca-pub-6698636577007643" // Replace with your AdSense client ID
      data-ad-slot={adSlot} // Replace with your Ad slot ID
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default GoogleAd;
