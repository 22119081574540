import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { db } from '../../firebase';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(-45deg, #fdee00, #7cb9e8, #74c365, #545aa7, #e4007c);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const Header = styled.header`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const HeaderButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin: 20px 0 0; 
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #545aa7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #e4007c;
  }

  @media (max-width: 600px) {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const HomeButton = styled(Button)`
  margin-right: 10px; /* spacing to the right if needed */
`;

const LogoutButton = styled(Button)`
  margin-left: 10px; /* spacing to the left if needed */
`;

const FormContainer = styled(motion.form)`
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  width: auto;
  max-width: 400px;
  max-height: fit-content;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: #333;
  }

  input, textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.9rem;
    box-sizing: border-box;
  }

  textarea {
    height: 70px;
    resize: vertical;
  }

  input[type="color"] {
    padding: 0;
    height: 35px;
    cursor: pointer;
  }

  button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #545aa7;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }

  button:hover {
    background-color: #e4007c;
  }

  @media (max-width: 600px) {
    padding: 15px;
  }
`;

const ScheduleHeader = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #fff;
  }
`;

const ScheduleList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
`;

const ScheduleItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  background: white;
  box-sizing: border-box;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ColorBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const ScheduleDetails = styled.div`
  flex: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.9rem;
  color: #333;

  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
`;

const ActionButton = styled(Button)`
  margin: 0;
  font-size: 0.9rem;
  width: auto;
  &:hover {
    background-color: #e4007c;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

function AdminDashboard() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);
  const [newSchedule, setNewSchedule] = useState({
    date: '',
    colorName: '',
    colorPickerValue: '#ffffff',
    quote: '',
  });
  const [editingSchedule, setEditingSchedule] = useState(null);
  const [loading, setLoading] = useState(true);

  const today = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD

  // Clean up old schedules
  const cleanupOldSchedules = async (fetchedSchedules) => {
    const oldSchedules = fetchedSchedules.filter(sch => sch.date < today);
    const futureSchedules = fetchedSchedules.filter(sch => sch.date >= today);

    // Delete old schedules from Firestore
    await Promise.all(oldSchedules.map(sch => deleteDoc(doc(db, 'schedules', sch.id))));
    
    return futureSchedules; 
  };

  // Fetch schedules from Firestore
  const fetchSchedules = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'schedules'));
      const schedulesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      
      const cleanedSchedules = await cleanupOldSchedules(schedulesData);
      setSchedules(cleanedSchedules);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add a new schedule
  const handleCreateSchedule = async (e) => {
    e.preventDefault();
    if (!newSchedule.date || !newSchedule.colorName || !newSchedule.quote) {
      alert('Please fill out all fields!');
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'schedules'), newSchedule);
      setSchedules((prev) => [
        ...prev,
        { id: docRef.id, ...newSchedule },
      ]);
      setNewSchedule({
        date: '',
        colorName: '',
        colorPickerValue: '#ffffff',
        quote: '',
      });
    } catch (error) {
      console.error('Error creating schedule:', error);
    }
  };

  // Update an existing schedule
  const handleEditSchedule = async (e) => {
    e.preventDefault();
    if (!editingSchedule.date || !editingSchedule.colorName || !editingSchedule.quote) {
      alert('Please fill out all fields!');
      return;
    }
    try {
      const scheduleDoc = doc(db, 'schedules', editingSchedule.id);
      await updateDoc(scheduleDoc, editingSchedule);
      setSchedules((prev) =>
        prev.map((schedule) =>
          schedule.id === editingSchedule.id ? editingSchedule : schedule
        )
      );
      setEditingSchedule(null);
    } catch (error) {
      console.error('Error updating schedule:', error);
    }
  };

  // Delete a schedule
  const handleDeleteSchedule = async (id) => {
    try {
      await deleteDoc(doc(db, 'schedules', id));
      setSchedules((prev) => prev.filter((schedule) => schedule.id !== id));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  // Logout functionality
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Title>Loading schedules...</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header>
        <HeaderButtons>
          <HomeButton onClick={() => navigate('/')}>Home</HomeButton>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </HeaderButtons>
        <Title>Admin Dashboard</Title>
      </Header>

      {editingSchedule ? (
        <FormContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          onSubmit={handleEditSchedule}
        >
          <label>Date:</label>
          <input
            type="date"
            value={editingSchedule.date}
            onChange={(e) =>
              setEditingSchedule({ ...editingSchedule, date: e.target.value })
            }
            required
          />
          <label>Color of the Day:</label>
          <input
            type="text"
            value={editingSchedule.colorName}
            onChange={(e) =>
              setEditingSchedule({
                ...editingSchedule,
                colorName: e.target.value,
              })
            }
            required
          />
          <label>Pick a Color:</label>
          <input
            type="color"
            value={editingSchedule.colorPickerValue}
            onChange={(e) =>
              setEditingSchedule({
                ...editingSchedule,
                colorPickerValue: e.target.value,
              })
            }
            required
          />
          <label>Quote:</label>
          <textarea
            value={editingSchedule.quote}
            onChange={(e) =>
              setEditingSchedule({
                ...editingSchedule,
                quote: e.target.value,
              })
            }
            required
          ></textarea>
          <button type="submit">Update Schedule</button>
        </FormContainer>
      ) : (
        <FormContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          onSubmit={handleCreateSchedule}
        >
          <label>Date:</label>
          <input
            type="date"
            value={newSchedule.date}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, date: e.target.value })
            }
            required
          />
          <label>Color of the Day:</label>
          <input
            type="text"
            value={newSchedule.colorName}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, colorName: e.target.value })
            }
            required
          />
          <label>Pick a Color:</label>
          <input
            type="color"
            value={newSchedule.colorPickerValue}
            onChange={(e) =>
              setNewSchedule({
                ...newSchedule,
                colorPickerValue: e.target.value,
              })
            }
            required
          />
          <label>Quote:</label>
          <textarea
            value={newSchedule.quote}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, quote: e.target.value })
            }
            required
          ></textarea>
          <button type="submit">Add Schedule</button>
        </FormContainer>
      )}

      <ScheduleHeader>
        <h3>Scheduled Colors and Quotes</h3>
        <Button onClick={fetchSchedules}>Refresh</Button>
      </ScheduleHeader>

      <ScheduleList>
        {schedules.map((schedule) => (
          <ScheduleItem key={schedule.id}>
            <ColorBox style={{ backgroundColor: schedule.colorPickerValue }}></ColorBox>
            <ScheduleDetails>
              <span>Date: {schedule.date}</span>
              <span>Color of the Day: {schedule.colorName}</span>
              <span>Quote: {schedule.quote}</span>
              <span>Color Code: {schedule.colorPickerValue}</span>
            </ScheduleDetails>
            <ActionButtons>
              <ActionButton onClick={() => setEditingSchedule(schedule)}>
                Edit
              </ActionButton>
              <ActionButton onClick={() => handleDeleteSchedule(schedule.id)}>
                Delete
              </ActionButton>
            </ActionButtons>
          </ScheduleItem>
        ))}
      </ScheduleList>
    </PageContainer>
  );
}

export default AdminDashboard;
