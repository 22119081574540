import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Ensure correct Firebase configuration
import GoogleAd from "../GoogleAd/GoogleAd";

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Choose between flex-start (top) or center (middle) vertical alignment */
  justify-content: flex-start;
  min-height: 100vh;
  background: linear-gradient(
    -45deg,
    #fdee00,
    #7cb9e8,
    #74c365,
    #545aa7,
    #e4007c
  );
  background-size: 200% 200%;
  animation: ${gradientAnimation} 15s ease infinite;
`;

const Logo = styled.img`
  width: 230px;
  margin: 30px 0 20px 0;
  animation: ${fadeIn} 2s ease;

  @media (max-width: 768px) {
    width: 100px;
  }
`;

const ContentBox = styled(motion.div)`
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 40px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 90%;
  }
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${(props) => props.color || "#fff"};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
  animation: ${fadeIn} 1.5s ease;

  p {
    font-size: 1.2rem;
    color: ${(props) => (props.isDark ? "#000" : "#333")};
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;

    p {
      font-size: 1rem;
    }
  }
`;

const Quote = styled.p`
  font-size: 1.5rem;
  color: #333;
  animation: ${fadeIn} 1.5s ease;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
  animation: ${fadeIn} 1.5s ease;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 15px 20px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  margin-top: auto;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 10px 15px;
  }
`;

function MainPage() {
  const [scheduledColor, setScheduledColor] = useState("#FFFFFF");
  const [colorName, setColorName] = useState("");
  const [quoteOfTheDay, setQuoteOfTheDay] = useState("Loading...");
  const [showColorCircle, setShowColorCircle] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchScheduleFromFirebase = async () => {
      setIsLoading(true);
      try {
        const today = new Date().toISOString().split("T")[0]; // Get today's date
        const q = query(collection(db, "schedules"), where("date", "==", today));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setScheduledColor(data.colorPickerValue || "#FFFFFF");
          setColorName(data.colorName || "Unnamed Color");
          setQuoteOfTheDay(data.quote || "No quote available.");
        } else {
          setScheduledColor("#FFFFFF");
          setColorName("No Color");
          setQuoteOfTheDay("No schedule available for today.");
        }
      } catch (error) {
        console.error("Error fetching schedule from Firestore:", error);
        setScheduledColor("#FFFFFF");
        setColorName("Error");
        setQuoteOfTheDay("Failed to load schedule.");
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchScheduleFromFirebase();
  }, []);
  

  const toggleColorDisplay = () => {
    setShowColorCircle(!showColorCircle);
  };

  const isDarkColor = () => {
    const color = scheduledColor.replace("#", "");
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  };

  if (isLoading) {
    return (
      <MainContainer>
        <ContentBox>
          <Heading>Loading...</Heading>
        </ContentBox>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Logo src="/logo.svg" alt="ColorUrDay Logo" />

      <ContentBox
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
      >
        <CircleWrapper
          color={showColorCircle ? scheduledColor : "#FFF"}
          isDark={isDarkColor()}
          onClick={toggleColorDisplay}
        >
          {showColorCircle ? null : <p>{colorName}</p>}
        </CircleWrapper>
        <Heading>ColorUrDay</Heading>
        <Quote>"{quoteOfTheDay}"</Quote>
      </ContentBox>

      <GoogleAd adSlot="0987654321" style={{ marginTop: "20px" }} />

      <Footer>© {new Date().getFullYear()} ColorUrDay. All rights reserved.</Footer>
    </MainContainer>
  );
}

export default MainPage;
